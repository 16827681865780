import { Injectable } from '@angular/core';
import { FileUpload } from '../shared/classes/file-upload';

declare var AWS: any;

@Injectable({
  providedIn: 'root'
})
export class FileService {
  loadingFlag: boolean = false;
  uploadPercentage: any = '';
  constructor() { }
  private basePath: string = '/';
  pushUpload(upload: any, subfolderPath: string) {
    console.log(upload, subfolderPath);
    let date = new Date();
    let name = '';
    name =
      name +
      date.getFullYear() +
      (date.getMonth() + 1) +
      date.getDate() +
      date.getHours() +
      date.getMinutes() +
      date.getSeconds() +
      '-' +
      date.getMilliseconds()+ upload.file.name
      // upload?.file?.name
      //   ? upload?.file?.name
      //   : 'thumbnail';
    let bucket = new AWS.S3({
      accessKeyId: 'AKIAX7VCYNZU77Q37QP4',
      secretAccessKey: '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',
      region: 'eu-west-2',
    });

    const params = {
      Bucket: 'influncer',
      Key: 'influencer' + subfolderPath + name,
      Body: upload.file,
      ContentType: upload.type,
      ACL: 'public-read-write',
    };

    return new Promise((resolve, reject) => {
      this.loadingFlag = true;
      let self = this;
      bucket
        .upload(params)
        .on('httpUploadProgress', function (evt: any) {
          self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);
          console.log(self.uploadPercentage);
          upload.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
          console.log(self.loadingFlag);
        })
        .send(function (err: any, data: any) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            self.loadingFlag = false;
            reject(err);
          }
          if (data) {
            console.log('Successfully uploaded file.', data);
            resolve({ url: data.Location });
            self.loadingFlag = false;
          }
        });
    });
  }
  deleteUpload(upload: FileUpload, subfolderPath: string) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}
