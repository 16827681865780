import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-campaigns-overview',
  templateUrl: './campaigns-overview.component.html',
  styleUrls: ['./campaigns-overview.component.css']
})
export class CampaignsOverviewComponent implements OnInit {

  overview:any = JSON.parse(localStorage.getItem('overview'))
  userData:any = JSON.parse(localStorage.getItem('clientProfile'));
  constructor(public useruser:FrontserviceService) {
    
   }

  ngOnInit() {
  }

}
