import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { LoadingComponent } from './shared/loading/loading.component';
import {
DialogComponent,
DialogContentDialog,
DialogDataDialog,
DialogDetailDialog,
DialogListDialog,
DialogSuccessDialog,
DialogInputDataDialog
} from './shared/dialog/dialog.component';





import { UsersService } from './services/users/users.service';
import { LoadingService } from './services/loading/loading.service';

import { MatDialogModule } from '@angular/material';
import {
  MatButtonModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatRadioModule,
  MatSelectModule,
  MatListModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
} from '@angular/material';


import { environment } from '../environments/environment';
import { AuthguardServiceService } from './authguard-service.service';
import { FrontendModule } from './frontend/frontend.module';
import { AdminModule } from './admin/admin.module';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { CommonModuleModule } from './common-module/common-module.module';
import { FooterComponent } from './shared/footer/footer.component';
import { SideBarComponent } from './frontend/shared/side-bar/side-bar.component';
import { CheckoutService } from './services/checkout.service';
import { Login1Component } from './login1/login1.component';
import { LoginClientComponent } from './admin/login-client/login-client.component';
// import { LayoutModuleModule } from './layouts/layout-module/layout-module.module';
import { PipeModule } from "./pipes/pipe.module";


@NgModule({
  entryComponents: [
    DialogComponent,
    DialogContentDialog,
    DialogDataDialog,
    DialogDetailDialog,
    DialogListDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,

  ],
  declarations: [
    AppComponent,
    SpinnerComponent,
    SnackbarComponent,
    LoadingComponent,
    DialogComponent,
    DialogContentDialog,
    DialogDataDialog,
    DialogDetailDialog,
    DialogListDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
    Login1Component,
    // LoginClientComponent,
    // SideBarComponent,


  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireStorageModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatSnackBarModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    CommonModuleModule,
    FrontendModule,
    NgbModule.forRoot(),
    RouterModule.forRoot(Approutes),
    //  FrontendModule,
    //   AdminModule,
    // LayoutModuleModule
  ],
  providers: [
    UsersService,
    LoadingService,
    SnackbarComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
    AuthguardServiceService,
    FooterComponent,
    CheckoutService,
    DatePipe,
    PipeModule

  ],
  exports: [
    LoadingComponent,
    PipeModule
  ],
  bootstrap: [AppComponent, LoadingComponent]
})
export class AppModule { }
