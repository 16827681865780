import { Component, OnInit } from '@angular/core';
import { FrontserviceService } from '../frontservice.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-live-campaign-listing',
  templateUrl: './influencer-new-opportunities.component.html',
  styleUrls: ['./influencer-new-opportunities.component.css']
})
export class InfluencerNewOpportunitiesComponent implements OnInit {

  livecam:any [] = [];
  clientid: any;
  constructor(public userser : FrontserviceService, public router : Router) {
 let clientData:any =  JSON.parse(localStorage.getItem('clientProfile')).data?JSON.parse(localStorage.getItem('clientProfile')).data:'';

 this.clientid = clientData._id
 console.log(this.clientid,'clien33')
    let d = new Date();
    this.userser.livecampaign(this.clientid).then((val:any) => {
      console.log(val);
      this.livecam = val.data;
    })
   }

   overview(eve:any){
     this.userser.overview.push(eve)
     console.log(this.userser.overview,'dat878787')
     localStorage.setItem('overview',JSON.stringify(eve))
    this.router.navigate(['/campaign'])
   }

  ngOnInit() {
  }

}
