import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FileUpload } from '../fileupload-client';
import { FileService } from '../file.service';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';


@Component({
  selector: 'app-signup-influencer',
  templateUrl: './signup-influencer.component.html',
  styleUrls: ['./signup-influencer.component.css']
})
export class SignupInfluencerComponent implements OnInit {

  currentUpload: any = FileUpload;
	selectedFiles: any = FileList;
  // imageurl: any;

  client={
    firstName:'',
    lastName:'',
    password:'',
    cpassword:'',
    email:'',
    role:'INFLUENCER',
    socialAccounts: [],
    profileImage:''
  };

  submitted:boolean=false;
  emailfield:boolean=false;
  socialArr:Array<String> = ["Facebook", "Instagram", "Tik-Tok", "YouTube"];
  socialMediaAccountArr:Array<SocialAcc> = [{username:"", handle:""}];

  constructor(public userser: FrontserviceService, public route : Router, public snack : ClientSnackbarComponent,
    public FileUploadService: FileService) { }

  ngOnInit() {
  }
  emailvalid(event:any){
    var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!this.client.email.match(validRegex) ){
      // this.submitted =false;

      this.emailfield =true;
    return
    }
    else{
      this.emailfield = false;
    }

  
  }

  addSocialAcc(){
    this.socialMediaAccountArr.push({username:"", handle:""});
  }

  submit(data:any){
    this.client.socialAccounts = this.socialMediaAccountArr
    
    this.submitted=true;

    this.userser.influencerAdd(data).then((val:any)=>{
      
      this.snack.snackbarSucces(val.message?val.message:"Some Error Occured");
      if (val.success) {
        this.route.navigate(['/thankyou-influencer'])
      }
    })
  }

  onFileInputProfileImage1(event: any) {
		console.log(event);
		if (
			event.target.files[0].size < 5000000 &&
			(event.target.files[0].type === 'image/jpeg' ||
				event.target.files[0].type === 'image/png' ||
				event.target.files[0].type === 'image/jpg')
		) {
			this.selectedFiles = event.target.files;
			let file = this.selectedFiles.item(0);
			this.currentUpload = new FileUpload(file);
			this.uploadImageProfileImage1();
		} else {
			// this.toast.toastError(
			//   'Allowed image format is jpeg,jpg or png with a max size 5 MB'
			// );
			// this.toastController.presentToast("Allowed image format is jpeg,jpg or png with a max size 5 MB")
			event.target.value = null;
		}
	}

  uploadImageProfileImage1() {
		this.FileUploadService.pushUpload(
			this.currentUpload,
			'/profileImage/'
		).then((res: any) => {
			console.log(res);
			this.client.profileImage = res.url;
			// console.logthis.ionicForm.value.profileimage);
		});
	}

  // login(){
  //   this.route.navigate(['/home/login-client'])
  // }

}


export interface SocialAcc{
  username:string;
  handle:string;
}