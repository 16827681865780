import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ResponsePopupComponent } from '../response-popup/response-popup.component';

@Component({
  selector: 'app-new-project-message',
  templateUrl: './new-project-message.component.html',
  styleUrls: ['./new-project-message.component.css']
})
export class NewProjectMessageComponent implements OnInit {

  constructor( public router :Router, private dialog : MatDialog  ) { }
  response(){
   
    const dialogref = this.dialog.open(ResponsePopupComponent, {
      
      data: {
        
      },
      panelClass: 'response-box'
    }); 
  }
  ngOnInit() {
  }

}
