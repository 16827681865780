import { Component, OnInit } from '@angular/core';
import { Campaign } from "./campaign-model";
import { MatDialog } from '@angular/material';
import{} from '@angular/router';
import { Router } from '@angular/router';
import { FrontserviceService } from '../frontservice.service';
import { ThankyouPopupComponent } from '../thankyou-popup/thankyou-popup.component';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { ContactCloutPopupComponent } from '../contact-clout-popup/contact-clout-popup.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.css']
})
export class CampaignFormComponent implements OnInit {

  model:Campaign = {
    campaignName: '',
    gender: '',
    location: '',
    age: '',
    interest: [],
    socialplatforms: '',
    contenttype: '',
    duration: 0,
    supportingdocuments: '',
    campaignoverview: '',
    competitors: '',
    relevanthashtagsphrases: '',
    management: null,
    campaigndate: '',
    clientId: JSON.parse(localStorage.getItem('clientProfile')).data._id
  };
  form_num:number = 1;
  interestData: any[] = [];

  country_lis: any[] = [
    { name: 'Afghanistan', code: 'AF' },
    { name: 'Åland Islands', code: 'AX' },
    { name: 'Albania', code: 'AL' },
    { name: 'Algeria', code: 'DZ' },
    { name: 'American Samoa', code: 'AS' },
    { name: 'AndorrA', code: 'AD' },
    { name: 'Angola', code: 'AO' },
    { name: 'Anguilla', code: 'AI' },
    { name: 'Antarctica', code: 'AQ' },
    { name: 'Antigua and Barbuda', code: 'AG' },
    { name: 'Argentina', code: 'AR' },
    { name: 'Armenia', code: 'AM' },
    { name: 'Aruba', code: 'AW' },
  ];

  countryCtrl: FormControl;

  filteredCountry: Observable<any[]>;




  constructor(private userser:FrontserviceService, private snack: ClientSnackbarComponent, public route : Router , private dialog : MatDialog) { 
    this.userser.getInterestData().then((val:any)=>{
      console.log("res5875959",val)
      this.interestData = val.data.interests || []
    })
    this.countryCtrl = new FormControl();
    this.filteredCountry = this.countryCtrl.valueChanges.pipe(
      startWith(''),
      map((country:any) =>
        country ? this.filtercountry(country) : this.country_lis.slice()
      )
    );
  }
  filtercountry(name: string) {
    let arr = this.country_lis.filter(
      (country) => country.name.toLowerCase().indexOf(name.toLowerCase()) === 0
    );

    return arr.length ? arr : [{ name: 'No Item found', code: 'null' }];
  }


  save(){
    let newModel:any = {...this.model}
    console.log(newModel);
    
    if(!newModel.campaignName || !newModel.gender || !newModel.age || !newModel.socialplatforms || !newModel.contenttype || !newModel.duration || !newModel.campaignoverview || newModel.management == null || !newModel.campaigndate){
      this.snack.snackbarSucces("Check Inputs...")
      return
    }
    
    if(newModel.interest) {
      let ind = this.interestData.findIndex( (d)=>d.id == newModel.interest);
        newModel.interest = ind != -1 ? [this.interestData[ind]] : [];
    }

    if(newModel.campaigndate && newModel.duration){
      let endDate = new Date(newModel.campaigndate);
      endDate.setDate(endDate.getDate() + newModel.duration * 7);
      newModel['campaignEndDate'] = (new Date(endDate).toISOString()).split('T')[0];
    }
    
    this.userser.addcampaign(newModel).then((val:any)=>{
      console.log(val,'add campaign')
      if(val.status == 'success'){
        this.snack.snackbarSucces("Added Successfully...")
        const dialogRef = this.dialog.open(ContactCloutPopupComponent, {
          panelClass:['Contact-clout-popup-box']
        });
        dialogRef.afterClosed().subscribe(() => {
          this.route.navigate(['home/live-campaign'])
        });
        return
      }
      this.snack.snackbarSucces("Some Error Occured, Check Inputs...")
    })
   

  }
  setClout(d:boolean){
   this.model.management = d
  }

  contactcloutpopup(){
    this.save()
  }

  ngOnInit() {
  }

}

