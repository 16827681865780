import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import{} from '@angular/router';
import { Router } from '@angular/router';
import { ReviewPostPopupComponent } from '../review-post-popup/review-post-popup.component';
import { CommissionPopupComponent } from '../commission-popup/commission-popup.component';




@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.css']
})
export class PostPreviewComponent implements OnInit {

  constructor(public route : Router , private dialog : MatDialog) { }

  reviewpopup(){
    {
      this.dialog.open(ReviewPostPopupComponent,{
        panelClass:['Reviewpopup-box'],
      })
    }
  }

  commissionpopup(){
    {
      this.dialog.open(CommissionPopupComponent,{
        panelClass:['Reviewpopup-box'],
      })
    }
  }



  ngOnInit() {
  }

}
