import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FrontendRoutes } from './frontend-routing.module';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSliderModule, MatButtonModule, MatProgressBarModule, MatIconModule, MatFormFieldModule, MatInputModule, MatRippleModule, MatTooltipModule, MatTableModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatListModule, MatStepperModule, MatCardModule, MatToolbarModule, MatDatepickerModule, MatSidenavModule, MatSlideToggleModule, MatGridListModule, MatSnackBarModule, MatCheckboxModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LoginComponent } from './login/login.component';
import { SignupClientComponent } from './signup-client/signup-client.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClientDashboardComponent } from './client-dashboard/client-dashboard.component';
import { FrontserviceService } from './frontservice.service';
import { ClientCampaignComponent } from './client-campaign/client-campaign.component';
import { CommonModuleModule } from '../common-module/common-module.module';
import { ClientSnackbarComponent } from './client-snackbar/client-snackbar.component';
import { ClientProfileComponent } from './client-profile/client-profile.component';
import { AddCampaignComponent } from './add-campaign/add-campaign.component';
import { FrontDialogComponent } from './front-dialog/front-dialog.component';
import { SignupInfluencerComponent } from './signup-influencer/signup-influencer.component';
import { InfluencerDashboardComponent } from './influencer-dashboard/influencer-dashboard.component';
import { InfluencerProfileComponent } from './influencer-profile/influencer-profile.component';
import { FileService } from './file.service';
import { HomeBlogComponent } from './home-blog/home-blog.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SideBarComponent } from './shared/side-bar/side-bar.component';
import { YourAccountComponent } from './shared/your-account/your-account.component';
import { ThankyouPopupComponent } from './thankyou-popup/thankyou-popup.component';
import { PricingComponent } from './pricing/pricing.component';
import { ArchivedCampaignsListingComponent } from './archived-campaigns-listing/archived-campaigns-listing.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { LiveCampaignListingComponent } from './live-campaign-listing/live-campaign-listing.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { CampaignsOverviewComponent } from './campaigns-overview/campaigns-overview.component';
import { StripeFailureComponent } from "./stripe-failure/stripe-failure.component";
import { StripeSuccessComponent } from "./stripe-success/stripe-success.component";
import { ThankyouInfluencerComponent } from './thankyou-influencer/thankyou-influencer.component';

// import { LayoutModuleModule } from '../layouts/layout-module/layout-module.module';
// import { DatePipe } from '@angular/common';


import { InfluencerCandidateListComponent } from './influencer-candidate-list/influencer-candidate-list.component';
import { ProfilePopupComponent } from './profile-popup/profile-popup.component';
import { CampaignApprovalComponent } from './campaign-approval/campaign-approval.component';
import { MessageComponent } from './message/message.component';
import { ReviewPostComponent } from './review-post/review-post.component';
import { PostPreviewComponent } from './post-preview/post-preview.component';
import { IndexMessageComponent } from './index-message/index-message.component';
import { ShortlistPopupComponent } from './shortlist-popup/shortlist-popup.component';
import { OptionPopupComponent } from './option-popup/option-popup.component';
import { SubscriptionPopupComponent } from './subscription-popup/subscription-popup.component';
import { InfluencerCandidateShortComponent } from './influencer-candidate-short/influencer-candidate-short.component';
import { CandidateComponent } from './candidate/candidate.component';
import { NotApprovedPopupComponent } from './not-approved-popup/not-approved-popup.component';
import { MessageSentPopupComponent } from './message-sent-popup/message-sent-popup.component';
import { NewProjectMessageComponent } from './new-project-message/new-project-message.component';
import { InfluencerMessageComponent } from './influencer-message/influencer-message.component';
import { ReviewPostPopupComponent } from './review-post-popup/review-post-popup.component';
import { CommissionPopupComponent } from './commission-popup/commission-popup.component';
import { ContactCloutPopupComponent } from './contact-clout-popup/contact-clout-popup.component';
import { ResponsePopupComponent } from './response-popup/response-popup.component';
import { ContentApprovalComponent } from './content-approval/content-approval.component';
import { MyPaymentComponent } from './my-payment/my-payment.component';
import { MaterialExampleModule } from '../mat-module/material.module';
import { LoginClientComponent } from '../admin/login-client/login-client.component';
import { InfluencerNewOpportunitiesComponent } from './influencer-new-opportunities/influencer-new-opportunities.component';
import { PipeModule } from '../pipes/pipe.module';

@NgModule({
  declarations: [
      HomeComponent,
      LoginComponent,
      SignupClientComponent,
      ClientDashboardComponent,
    //  ClientfullComponent,
      ClientCampaignComponent,
      
    // ClientSnackbarComponent,
      
    ClientProfileComponent,
      
    AddCampaignComponent,
      
    FrontDialogComponent,
      
    SignupInfluencerComponent,
      
    InfluencerDashboardComponent,
      
    InfluencerProfileComponent,
    SideBarComponent,
    HomeBlogComponent,
      HeaderComponent,
      FooterComponent,
      YourAccountComponent,
      ThankyouPopupComponent,
      PricingComponent,
      ArchivedCampaignsListingComponent,
      PaymentDetailsComponent,
      LiveCampaignListingComponent,
      CampaignFormComponent,
      CampaignsOverviewComponent,

      ThankyouInfluencerComponent,
    //   NavigationComponent,
    // BreadcrumbComponent,
    // SidebarComponent,


      InfluencerCandidateListComponent,
    ProfilePopupComponent,
    CampaignApprovalComponent,
    MessageComponent,
    ReviewPostComponent,
    PostPreviewComponent,
    IndexMessageComponent,
    StripeSuccessComponent,
    StripeFailureComponent,
    ShortlistPopupComponent,
    OptionPopupComponent,
    SubscriptionPopupComponent,
    InfluencerCandidateShortComponent,
    CandidateComponent,
    NotApprovedPopupComponent,
    MessageSentPopupComponent,
    NewProjectMessageComponent,
    InfluencerMessageComponent,
    ReviewPostPopupComponent,
    CommissionPopupComponent,
    ContactCloutPopupComponent,
    ResponsePopupComponent,
    ContentApprovalComponent,
    MyPaymentComponent,
    LoginClientComponent,
    InfluencerNewOpportunitiesComponent,

    ],
    providers:[
      FrontserviceService,
    ClientSnackbarComponent,
    FileService,
  
       

    ],
  imports: [
    CommonModule,
    FormsModule,
    // LayoutModuleModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule.forChild(FrontendRoutes),
    MatSliderModule,
    MatButtonModule,
    MatProgressBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatListModule,
    MatStepperModule,
    MatCardModule,
    PerfectScrollbarModule,
    LayoutModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatMomentDateModule,
    MatGridListModule,
    MatSnackBarModule,
    MatCheckboxModule,
    // CommonModuleModule,
    CommonModule,
    CommonModuleModule,
    MaterialExampleModule,
    PipeModule
  ],
  // exports:[ LayoutModuleModule,]
})
export class FrontendModule { }
