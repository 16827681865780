import { Component, OnInit } from '@angular/core';
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutService } from '../../services/checkout.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  clientData:any =  JSON.parse(localStorage.getItem('clientProfile')).data?JSON.parse(localStorage.getItem('clientProfile')).data:'';
  stripeCustomerId:string = this.clientData.stripeCustomerId;
  stripePromise = loadStripe('pk_test_51MshmVSAWwXjbRpdUNGEU06O1HgLv3RYTmzuqhUmaCEKG0rkXEbt0eLuVDzzDWF8EzoJsJZyFfiBe85cU1MApWcU00G86sDrFs');;
  
  constructor(private checkout: CheckoutService) { }

  ngOnInit() {
    this.stripeCreateCustomer(this.clientData);
  }

  async makePayment(priceId: string) {

    const payment = {
      stripeCustomerId: this.stripeCustomerId,
      clientReferenceId: this.clientData._id,
      priceId: priceId,
      successUrl: `${window.location.href}/stripe-success`,
      cancelUrl: `${window.location.href}/stripe-failure`,
    };

    const stripe = await this.stripePromise;

    this.checkout.payment(payment).subscribe(res=>{
      let resp:any = res;
      if(resp.sessionId){
        localStorage.setItem('stripeSessionId', resp.sessionId);
        stripe.redirectToCheckout({
          sessionId: resp.sessionId,
        });
      }
    });

  }

  async stripeCreateCustomer(data: any) {
    if(!this.clientData.stripeCustomerId){
      let d:any = {
        name: data.firstName,
        email: data.email ,
        clientId: data._id
      }
      
      await this.checkout.createCustomer(d).subscribe(d1 =>{
        this.updateStripeCustomerId(d1);
      });
    }
  }

  updateStripeCustomerId(d:any){
    
    let data = JSON.parse(localStorage.getItem('clientProfile'));
    this.stripeCustomerId = d.data.id;
    data.data.stripeCustomerId = d.data.id
    localStorage.setItem('clientProfile', JSON.stringify(data));
  }

}
