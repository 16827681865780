import { Component, OnInit } from '@angular/core';
import { NotApprovedPopupComponent } from '../not-approved-popup/not-approved-popup.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-influencer-message',
  templateUrl: './influencer-message.component.html',
  styleUrls: ['./influencer-message.component.css']
})
export class InfluencerMessageComponent implements OnInit {

  constructor(private dialog : MatDialog) { }

  notapppopup(){
    {
      this.dialog.open(NotApprovedPopupComponent,{
        panelClass:['notapprovedmessage-box'],
      }) 
    }
  }

  ngOnInit() {
  }

}
