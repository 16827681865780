import { Component, OnInit } from '@angular/core';
import{} from '@angular/router';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { NotApprovedPopupComponent } from '../not-approved-popup/not-approved-popup.component';
@Component({
  selector: 'app-index-message',
  templateUrl: './index-message.component.html',
  styleUrls: ['./index-message.component.css']
})
export class IndexMessageComponent implements OnInit {
  company:any;
  isClient:boolean = false
  isInfluencer:boolean = false
  constructor(public route : Router , private dialog : MatDialog) {
    let use:any = localStorage.getItem('clientProfile');
    use = use ? JSON.parse(use) : {}
    this.company = use.data.companyName;
    this.isClient = use.data.role==='CLIENT' ? true : false
    this.isInfluencer = use.data.role=== 'INFLUENCER' ? true : false
   }
  notapppopup(){
    {
      this.dialog.open(NotApprovedPopupComponent,{
        panelClass:['notapprovedmessage-box'],
      }) 
    }
  }
      
  ngOnInit() {
  }
  navigateToMessage () {
  if(this.isClient){
    this.route.navigate(['/home/campaign-message'])
  } 
  }
  navigateToInfluMsg(){
    if(this.isInfluencer){
    this.route.navigate(['/home/influencer-message'])
      }  }
}


