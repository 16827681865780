import { Component, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material';

import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
show : boolean;
subscription: Subscription;

  constructor(private loadingService : LoadingService) { 
  	this.show = false;
  }

  ngOnInit() {
    this.subscription = this.loadingService.getLoading().subscribe(show => {
      if(show){
        this.presentLoading();
      }else{
        this.hideLoading();
      }
    });
  }

  presentLoading() {
    this.show = true;
  }

  hideLoading(){
    setTimeout(() => {
      this.show = false;
    }, 1);
  }

  // ngOnDestroy() {
  //       // unsubscribe to ensure no memory leaks
  //       this.subscription.unsubscribe();
  //   }

}