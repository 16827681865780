import { Routes } from '@angular/router';
import { LoginComponent } from './frontend/login/login.component';
import { LoginClientComponent } from './admin/login-client/login-client.component';

export const Approutes: Routes = [
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch : 'full'
  },
  {
    path: 'home',
    loadChildren: './frontend/frontend.module#FrontendModule',
  },
  {
      path: 'login-influencer',
      component: LoginComponent,
      // canActivate: [AuthenticationGuard]
    },
    {
      path: 'login-client',
      component: LoginClientComponent,
      // canActivate: [AuthenticationGuard]
    },
  // {
  //   path: '**',
  //   redirectTo: '/admin',
  //    pathMatch : 'full'
  // },
];
