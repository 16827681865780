import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

loginFormModel:any;
dataset:any='client'
dataset1:any='influencer'
user:any;

  constructor(public route : Router, public userser: FrontserviceService, public router:ActivatedRoute, public snack : ClientSnackbarComponent) { 
    this.loginFormModel = {
      "email" : "",
      "password" : "",
      // "realm" : "admin"
    }

    this.router.queryParams.subscribe((param:any)=>{
     
        this.user = param.special
      
      console.log(this.user ,'param value')
    })

  }

  ngOnInit() {
  }

  doLogin(data:any){
    this.userser.clientlogin(data).then((val:any)=>{
      console.log(val,'client login data')
      localStorage.setItem('user',val.data.role)
      localStorage.setItem('clientProfile',JSON.stringify(val))
      if(val.data.role == 'CLIENT'){
      // this.route.navigate(['home/brief-campaign', {replaceUrl:true}])
      setTimeout(()=>{
        this.route.navigate(["home/brief-campaign"], {replaceUrl:true});
      });
      }
      else if(val.data.role == 'INFLUENCER')
      {
        setTimeout(()=>{
          this.route.navigate(["home/new-opportunities"], {replaceUrl:true});
        });
      }
  })
  // this.snack.snackbarError("Email/Password Incorrect")

    // console.log(data,'client data')
    // this.route.navigate(['/home/influencer-dashboard'])
  }

  gosign(){
    // if(this.user == 'client'){
      this.route.navigate(['/home/sign-up-client'])
    // }
    // else if(this.user == 'influencer'){
    //   this.route.navigate(['/home/sign-up-influencer'])
    // }
  }
}
