import { Component, OnInit } from '@angular/core';
import { AppSettings } from '../../core/constant';
import { UsersService } from '../../services/users/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.css']
})
export class LoginClientComponent implements OnInit {
  hide = true;
  site_name: string;
  loginFormModel : any;
  dataset:any='Admin'
  constructor(  public usersService : UsersService,
    public router : Router) { 
      this.site_name = AppSettings.SITE_NAME;

      this.loginFormModel = {
        "email" : "",
        "password" : "",
        // "realm" : "admin"
      }
    }

  ngOnInit() {
  }
  doLogin(data:any){
    console.log(data,'dataaaaaa')
    if(data.email != '' && data.password != ''){
    this.usersService.loginuser(data).then((data:any) => {
      if(data){

        localStorage.setItem('user',data.data.role)
        localStorage.setItem('userProfile',JSON.stringify(data))
        this.router.navigate(['/admin/dashboard']);
        console.log(data,'loginnn')
      }
      // this.goToAdmin();
    })
  }
    //   this.usersService.getUserDetails().then(dataDetail => {
    // })
  

  
}
}
