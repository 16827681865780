import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.css']
})
export class ProfilePopupComponent implements OnInit {
  onAdd = new EventEmitter();
  overViewData:any = JSON.parse(localStorage.getItem("overview"));
  type:string = this.overViewData.socialplatforms.toLowerCase().replace("-", "");

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog : MatDialog) {
    
  }

  onButtonClick(d:any) {
    this.data.isShortList = true;
    this.onAdd.emit(d);
  }

  ngOnInit() {
  }

  cancel(){
    this.dialog.closeAll();
  }
}
