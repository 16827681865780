import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientSnackbarComponent } from '../client-snackbar/client-snackbar.component';
import { FrontserviceService } from '../frontservice.service';


@Component({
  selector: 'app-signup-client',
  templateUrl: './signup-client.component.html',
  styleUrls: ['./signup-client.component.css']
})
export class SignupClientComponent implements OnInit {

  client={
    fisrtName:'',
    lastName:'',
    password:'',
    cpassword:'',
    email:'',
    companyName:'',
    contactNumber:''
  };

  submitted:boolean=false;
  emailfield:boolean=false;

  constructor(public userser: FrontserviceService, public route : Router, public snack : ClientSnackbarComponent) { }

  ngOnInit() {
  }
  emailvalid(event:any){
    var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if(!this.client.email.match(validRegex) ){
      // this.submitted =false;

      this.emailfield =true;
    return
    }
    else{
      this.emailfield = false;
    }

  
  }

  submit(data:any){
    this.submitted=true;
    

    if(!data.email || !data.firstName || !data.password || !data.cpassword || !data.companyName || !data.contactNumber){
      return;
    }
    // var validRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    // if(!this.client.email.match(validRegex) ){
    //   // this.submitted =false;

    //   this.emailfield =true;
    // return
    // }
   
    console.log(data,'sign up data')
    this.userser.clientadd(data).then((val:any)=>{
      // console.log(val,'Client Add')
      this.snack.snackbarSucces(val.message?val.message:"Some Error Occured");
      if (val.success) {
        this.route.navigate(['/home/login'])
      }
    })
  }

  login(){
    this.route.navigate(['/home/login'])
  }

}
