import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public route : Router) { }

  ngOnInit() {
  }

  gologin(data:any){
    let navigationExtra : NavigationExtras = {
      queryParams:{
        special:data
      }
    }
    this.route.navigate(['/login-client'],navigationExtra)
  }

}
